/**
 * Display the Loyalty Prices available
 */
Drupal.behaviors.loyaltyPrices = {
  /**
   * Flag to ensure this JS is only attached once.
   * @type {boolean}
   */
  currency: false,
  attached: false,
  attach: function (context, settings) {
    var self = this;
    var isSPP = $(context.body).hasClass('page-product');

    if (this.attached || (isSPP && $('.js-price-loyalty', context).length < 1)) {
      return null;
    }

    var pageDataHash = this.getPageDataHash();
    this.mppProductsData = pageDataHash.get('custom-mpp');
    if (!this.mppProductsData) {
      this.mppProductsData = pageDataHash.get('catalog-mpp');
      this.mppProductsData = this.mppProductsData ? this.mppProductsData.categories[0] : {};
    }

    site.onLoadRpc.requests = site.onLoadRpc.requests || [];
    site.onLoadRpc.requests.push({
      'method':   'offers.query',
      'params':   [{
        'view':'russia_loyalty'
      }],
      'isSPP': isSPP,
      'onSuccess' : function (response) {
        if (!response || !response.result || !response.result.value || !response.result.value.offers) {
          return;
        }
        var v = response.result.value;

        // Extract the offer status into a loyalty level array
        page_data.offers_data = page_data.offers_data || {};
        page_data.offers_data.loyalty_offers = page_data.offers_data.loyalty_offers || [];
        var highest_disc_met = 0;
        var highest_discount = 0;
        $.each(v.offers, function(ocode, offer){
          var loyalty_offer = {};
          loyalty_offer.offer_code = ocode;
          loyalty_offer.discount = parseInt(offer.benefit_fields.PercentDiscount.value);

          // If it's the highest so far, update the flag
          if (highest_disc_met < loyalty_offer.discount && parseInt(offer.fields.meets_criteria)) {
            highest_disc_met = loyalty_offer.discount;
          }
          if (highest_discount < loyalty_offer.discount) {
            highest_discount = loyalty_offer.discount;
          }

          page_data.offers_data.loyalty_offers.push(loyalty_offer);
        })

        // Sort lowest to highest
        page_data.offers_data.loyalty_offers.sort(function(a, b) {
          return ( (a.discount == b.discount) ? 0 : ((a.discount > b.discount) ? 1 : -1)) ;
        });

        // Recognized, but not logged in customer will show their last known status
        // Set the "met" and "too_low" state of their level and clear the anonymous flag
        // (Checks first_name as the state flags default to level 1)
        page_data.is_anonymous_user = site.userInfoCookie.getValue('signed_in') != '1';
        if (page_data.is_anonymous_user) {
          var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
          if (
            persistentCookie.first_name &&
            persistentCookie.is_loyalty_member == '1' &&
            persistentCookie.loyalty_level
          ) {
            highest_disc_met = page_data.offers_data.loyalty_offers[persistentCookie.loyalty_level - 1].discount;
            page_data.is_anonymous_user = false;
          }
        }

        // If they don't meet any criteria and *are* signed in, then they're eligible for the lowest level
        if (highest_disc_met == 0 && !page_data.is_anonymous_user) {
          highest_disc_met = page_data.offers_data.loyalty_offers[0].discount;
        }

        // Filter the offers and set as met only the highest offer that meets_criteria
        $.each(page_data.offers_data.loyalty_offers, function(i, el) {
          el.met = (el.discount == highest_disc_met);
          el.too_low = (el.discount < highest_disc_met);
          el.too_high = (el.discount > highest_disc_met && el.discount != highest_discount);
        });

        if (isSPP) {
          self.setEvents(context);
          $(document).on('productQV:rendered:LoyaltyPrices', function(e, container) {
            self.setEvents(container);
          });
          $(document).on('productQV:rendered:LoyaltyPrices:popupQS productQV:rendered:LoyaltyPrices:miniBag', function(e, container) {
            self.setEventsMPP(container);
          });
        } else {
          self.setEventsMPP(context);
          $(document).on('productQV:rendered:LoyaltyPrices', function(e, container) {
            self.setEventsMPP(container);
          });
          $(document).on('productQV:rendered:LoyaltyPrices:popupQS', function(e, container) {
            self.setEventsMPP(container);
          });
        }
        $(document).on('endeca.search.prices.updated endeca.search.results.loaded', function(e, container) {
          if (container && container.nodes) {
            container = container.nodes.resultsContainer || container.nodes.wrapper || $(document);
          }
          self.setEventsMPP(container, { processOnce: true });
          Drupal.ELB.refreshSelectBoxes($('.js-price-loyalty.selectBox', container));
        });
      },
      'onFailure' : function() {
        var $productLoyalty;
        if (isSPP && $('.product-full__price-loyalty--text', context).length >= 1) {
          $productLoyalty = $('.product-full__price-loyalty--text');
        } else {
          $productLoyalty = $('.js-price-loyalty', context);
        }
        if ($productLoyalty) $productLoyalty.hide();
      }
    });
  },
  getPageDataHash: function() {
    var generic = window.generic || {};
    var page_data = window.page_data || {};
    return new generic.Hash(page_data);
  },
  setEvents: function(context, settings) {
    var self = this;
    this.attached = true;
    var $productLoyalty = $('.product-full__price-loyalty--text', context);
    var $miniBagProductLoyalty = $('.spp-product__mini-bag', context);
    var isCartConfirm = $(context).hasClass('cart-confirm__content');
    var tierDescriptionList = $productLoyalty.length && $productLoyalty.attr('data-tier-desc-list') ? $productLoyalty.attr('data-tier-desc-list').split(',') : [];
    var $discoverMore = $('.discover-more', context);

    tierDescriptionList.map(function (str) {
      return str.trim();
    });

    var currency = $productLoyalty.attr('data-currency-symbol');

    var $productPriceContainer = $productLoyalty.find('.product-full__price-loyalty-container');
    var $container = $productPriceContainer.find('.product-full__price-loyalty').filter(function( index ) {return $( this ).data( 'is-discountable' ) == '1'});
    var productPrice = $container.find('.product-full__price');

    // Add active class for full price
    if (page_data.is_anonymous_user) {
      $container.addClass('active');
    }
    // Update the mini bag prices
    if ($miniBagProductLoyalty.find('.js-price-loyalty').length || (isCartConfirm && $(context).find('.js-price-loyalty').length)) {
      $element = isCartConfirm ? $(context) : $miniBagProductLoyalty;
      self.setEventsMPP($element);
      // Update the prices for multi-size products
      if ($element.find('select.js-size-price-loyalty').length) {
        self.setEventsMPP($($element.find('select.js-size-price-loyalty')));
      }
    }
    // Update the size drop down
    var $sppSizeSelect = $('.js-spp-product-full__price-size-select', context);
    if ($sppSizeSelect.length) {
      self.setEventsMPP($sppSizeSelect);
    }
    // Update the prices for View More products
    if ($discoverMore.find('.js-price-loyalty').length) {
      $element = $discoverMore;
      self.setEventsMPP($element);
    }

    if (productPrice.length > 0) {
      $productLoyalty.show();
      var loyalty_offers = page_data.offers_data.loyalty_offers;
      $.each(loyalty_offers, function(i, el) {
        // Ignore/hide levels that are below the current user when signed in
        if ((el.too_low || el.too_high) && !page_data.is_anonymous_user) {
          return;
        }

        $tmp = $container.clone();
        $productPriceContainer.prepend($tmp);

        $tmp.removeClass('active');

        var priceText = $tmp.find('.product-full__price-text');
        var $descriptionText = $tmp.find('.product-full__price-description--text');

        $tmp.addClass('tier' + (i+1));

        // Highlight the "met" levels when signed in only
        if (el.met && !page_data.is_anonymous_user) {
          $tmp.addClass('active');
        }

        for ( var j = 0, len = productPrice.length; j < len; j++ ) {
          var textPrice = $(productPrice[j]).text();
          var priceBase = parseInt(textPrice, 10);

          discountedPrice = priceBase - priceBase * el.discount / 100;
          $(priceText[j]).find('.product-full__price').text(discountedPrice + ' ' + currency);
        }

        if (tierDescriptionList[i]) {
          text = tierDescriptionList[i];
          $descriptionText.text(text);
        }
      });
    }
  }, 
  setEventsMPP: function(context, settings) {
    var self = this;
    self.attached = true;
    settings = settings || {};

    var mppPageData = self.mppProductsData;
    var mppProducts = mppPageData && mppPageData.products ? mppPageData.products : [];
    var isProductSizeLoyalty = $(context).hasClass('js-size-price-loyalty');
    var $productLoyalty = isProductSizeLoyalty ? $(context) : $('.js-price-loyalty', context);
    var regularPrice, discountedPrice, selectedSize, $quickshop, $grid, $selected, skusLen;

    if (!self.currency) {
      $productLoyalty.each(function() {
        self.currency = self.currency || $(this).attr('data-currency-symbol');
        // break the loop (via false return) when set
        return !self.currency;
      });
    }
    var loyalty_mpp_prefix = (page_data.is_anonymous_user && $productLoyalty.attr('loyalty-price-prefix')) ? $productLoyalty.attr('loyalty-price-prefix')+' ' : '';
    var loyalty_mpp_suffix = (page_data.is_anonymous_user && $productLoyalty.attr('loyalty-price-suffix')) ? $productLoyalty.attr('loyalty-price-suffix') : '';

    var loyalty_offers = page_data.offers_data.loyalty_offers;
    var rangeSeparator = ' - ';
    var offer_met = false;

    $.each(loyalty_offers, function(i, el) {
      if (el.met || (page_data.is_anonymous_user && loyalty_offers.length === i + 1)) {
        offer_met = $.extend({}, el);
        offer_met.met = 1;
      }
    });

    if (isProductSizeLoyalty && $productLoyalty && $productLoyalty.length && offer_met) {
      $options = $productLoyalty.find('option');
      $.each($options, function(k, el) {
        var $productPrice = $(this);
        var priceBase, discountedPrice;
        var isDiscountable = $productPrice.data('is-discountable') || $productPrice.hasClass('js-is-discountable') || 0;

        if (isDiscountable) {
          size = $productPrice.data('loyalty-option-size') || $productPrice.data('sku-size');
          price = $productPrice.data('loyalty-option-price') || $productPrice.data('sku-price');

          price = price.replace(/&#?[a-z0-9]+;/g, '');
          priceBase = price.split(' ' + self.currency).join('');

          discountedPrice = priceBase - priceBase * offer_met.discount / 100;
          price = loyalty_mpp_prefix + discountedPrice + ' ' + self.currency;
          $productPrice.data('loyalty-option-discounted-price', discountedPrice + ' ' + self.currency);
          if (size) {
            $productPrice.html(size + rangeSeparator + price);
          } else {
            $productPrice.html(price);
          }
        }
      });
      Drupal.ELB.refreshSelectBoxes($productLoyalty);
      // handle updating MPP & QS grid
      $quickshop = $productLoyalty.closest('.quickshop');
      if (!$quickshop.length) {
        $quickshop = $productLoyalty.closest('.quickshop-inline');
      }
      $grid = $quickshop.length ? $quickshop.find('.js-loyalty-price-grid') : $productLoyalty.closest('.js-product-brief').find('.js-loyalty-price-grid');
      if ($grid.length) {
        $selected = $productLoyalty.children('option:selected');
        regularPrice = $selected.data('loyalty-option-price') || $selected.data('sku-price');
        discountedPrice = $selected.data('loyalty-option-discounted-price') || regularPrice;
        selectedSize = $selected.data('loyalty-option-size') || $selected.data('sku-size');
        $grid.find('.js-price-loyalty').html(discountedPrice);
        $grid.find('.js-price-standard').html(regularPrice);
        $grid.find('.js-loyalty-size').html(selectedSize);
        if (!page_data.is_anonymous_user) {
          // highlight loyalty pricing for logged in user. For now, we'll be agnostic about specific elements, etc
          $grid.find('.js-loyalty-price-grid-row').toggleClass('active');
        }
        if ($selected.data('is-discountable')) {
          $grid.show();
        } else {
          // in theory, we don't need to do the above updates if the selected sku isn't discountable
          // but it's better to update the grid to keep everything consistent
          $grid.hide();
        }
      }
    } else if ($productLoyalty && $productLoyalty.length && offer_met) {
      $productLoyalty = $productLoyalty.filter(function( index ) {
        var isDiscountable = $(this).data('is-discountable') || $(this).hasClass('js-is-discountable');
        var canProcess = !settings.processOnce || !$(this).data('loyalty-price-processed');
        return isDiscountable && canProcess;
      });
      $.each($productLoyalty, function(k, el) {
        var $productPrice = $(this);
        var prefixText, $priceGrid, product;
        var $productLoyaltyParent = $productPrice.parents('.js-product-brief');
        var productId = $productLoyaltyParent.data('product-id');

        if ($productPrice.length > 0) {
          if (productId) {
            mppProducts.forEach(function(prod) {
              if (prod.PRODUCT_ID === productId) {
                product = prod;
                return false;
              }
            });
          }
          // if we have a nested element, we'll try to operate on that instead ... esp. for Endeca sized product results
          $productPrice = $productPrice.children().length > 0 ? $productPrice.children().first() : $productPrice;
          $priceGrid = $productPrice.closest('.js-loyalty-price-grid');
          // only add the prefix if we aren't in the price grid. similar to SPP...
          // no reason to display 'From...' when we are already showing all prices anyway
          prefixText = $priceGrid.length === 0 ? loyalty_mpp_prefix : '';
          for ( var j = 0, len = $productPrice.length; j < len; j++ ) {
            var priceBase, priceBase2, discountedPrice, discountedPrice2, trailingText;

            var textPrice = $($productPrice[j]).text();
            textPrice = textPrice.replace(/&#?[a-z0-9]+;/g, '');
            textPrice = textPrice.split(' '+self.currency).join('');
            if (textPrice.indexOf(rangeSeparator) !== -1) {
              textPrice = textPrice.split(rangeSeparator);
            }
            if (typeof(textPrice)=='string') {
              textPrice = textPrice.trim();
              // parseInt is destructive to anything after the number, so we preserve it here
              textPrice = textPrice.split(/\W+(.+)/);
              trailingText = textPrice[1];
              textPrice = textPrice[0];
              priceBase = parseInt(textPrice, 10);
            } else if (typeof(textPrice)=='object') {
              priceBase = parseInt(textPrice[0], 10);
              priceBase2 = parseInt(textPrice[1], 10);
            }

            discountedPrice = priceBase - priceBase * offer_met.discount / 100;

            // Price of non discountable sku won't need the loyalty discount.
            if (product && product.shaded && product.skus) {
              skusLen = product.skus.length;
              while (skusLen-- > 0) {
                if (!product.skus[skusLen].DISCOUNTABLE && product.skus[skusLen].PRICE === priceBase) {
                  discountedPrice = priceBase;
                  break;
                }
              }
            }

            if (priceBase2 > 0) {
              discountedPrice2 = priceBase2 - priceBase2 * offer_met.discount / 100;
              // Per request... MPP / multi-sized / signed out... no prefix
              $productPrice.text(discountedPrice + ' ' + self.currency + rangeSeparator + discountedPrice2 + ' ' + self.currency + loyalty_mpp_suffix);
            } else {
              trailingText = trailingText ? ' ' + trailingText : '';
              $productPrice.text(prefixText + discountedPrice + ' ' + self.currency + loyalty_mpp_suffix + trailingText);
            }
          }
          if (!page_data.is_anonymous_user) {
            // highlight loyalty pricing for logged in user. For now, we'll be agnostic about specific elements, etc
            $priceGrid.find('.js-loyalty-price-grid-row').toggleClass('active');
          }
          $productPrice.data('loyalty-price-processed', 1);
        }
      });
    }
  }
}
